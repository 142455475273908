/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import Page from "../layouts/page";
import { Link } from "gatsby";

const hero = css`
font-size: 4em;
font-weight: normal;
`

const errorSpan = css`
color: #BB0B3E;
`

const byline = css`
font-weight: lighter;
font-size: 2em;
`

const NotFoundPage = () => {
    return <Page title="Not Found">
        <h1 css={hero}><span css={errorSpan}>404</span> - Page Not Found</h1>
        <p css={byline}>The requested page could not be found</p>

        <p>We couldn't find the content you were looking for, <Link to="/">return home?</Link></p>
    </Page>
}

export default NotFoundPage
